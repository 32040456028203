<template>
	<div class="body">
		<el-row :gutter="20">
			<el-col :span="20" style="height: 22px;">
				<div class="grid-content bg-purple" style="min-height: 20px;">
					<div class="steps-tip first-tip" style="height:20px;"><em>1</em>设定价格</div>
				</div>
			</el-col>
			<el-col :span="4">
			</el-col>
		</el-row>
		<el-row :gutter="24" style="height: 40px;">
			<el-col :span="5">
				<div style="display: flex;align-items: center;margin-left: 20px;height: 32px;">
					<div style="margin-right: 10px;">改价类型</div>
					<el-radio v-model="radio" label="1">涨价</el-radio>
					<el-radio v-model="radio" label="2">降价</el-radio>
				</div>
			</el-col>
			<el-col :span="15">
				<div style="display: flex;align-items: center;margin-left: 20px;">
					<div style="margin-right: 10px;">改价方式</div>
					<el-radio v-model="fangshi" label="1">固定金额</el-radio>
					<el-radio v-model="fangshi" label="2">百分比</el-radio>
					<div style="display: flex;align-items: center;margin-left: 20px;">
						<div style="margin-right: 10px;width: 100px;">改价幅度</div>
						<el-input size="small" type="number" placeholder="请输入价格" v-model="price">
							<template slot="append">{{fangshi == '1'? '元':'%'}}</template>
						</el-input>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="20">
				<div class="grid-content bg-purple">
					<div class="steps-tip first-tip"><em>2</em>选择商品</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="grid-content bg-purple">
					<el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="keywords">
					</el-input>
				</div>
			</el-col>
		</el-row>
		<el-container style="height: calc(100vh - 290px); border: 1px solid #eee;width: 100%;">
			<el-aside width="200px" style="background-color: rgb(238, 241, 246)">
				<el-menu :default-openeds="['1', '3']" mode="vertical">
					<el-menu-item @click="actmenu(0)" index="0">
						全部分类
					</el-menu-item>
					<el-menu-item @click="actmenu(item.id)" v-for="(item, index) in catelist" :key="item.id"
						:index="index">
						<div style="display: flex;align-items: center;justify-content: center;">
							<div style="flex: 1;">{{item['name']}}</div><em class="u-em">1</em>
						</div>
					</el-menu-item>
				</el-menu>
			</el-aside>
			<div style="width: 100%;">
				<el-card style="">
					<el-table tooltip-effect="dark" height="calc(100vh - 360px)" border :data="pagedTableData"
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column prop="name" label="商品"> </el-table-column>
						<el-table-column prop="guige" label="规格"> </el-table-column>
						<el-table-column prop="isp" label="简单商品"> </el-table-column>
						<el-table-column prop="jiage" label="价格"> </el-table-column>
						<el-table-column prop="hdprice" label="活动价"> </el-table-column>
						<el-table-column prop="kucun" label="库存"> </el-table-column>
						<el-table-column prop="istb" label="同步店铺"> </el-table-column>
						<!-- 其他列 -->
					</el-table>
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:page-sizes="[10, 20, 30, 40, 50]" :current-page.sync="pageNo"
						layout="total, sizes, prev, pager, next, jumper" :total="tableData.length">
					</el-pagination>
				</el-card>
			</div>
		</el-container>
		<el-row v-if="step==1">
			<div style="text-align: right;margin-top: 10px;padding-right: 50px;">
				<el-button @click="xiayibu" type="primary" round>下一步</el-button>
			</div>
		</el-row>
		<div style="margin-top: 20px;" v-if="step==2">
			<el-row :gutter="20">
				<el-col :span="20">
					<div class="grid-content bg-purple">
						<div class="steps-tip first-tip"><em>2</em>选择店铺</div>
					</div>
				</el-col>
			</el-row>
			<div>
				<el-row :gutter="20">
					<el-col :span="4">
						<el-select v-model="value" placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-col>
					<el-col :span="6">
						<el-input placeholder="搜索店铺名称/ID（多个以,分隔）" v-model="keywords1" class="input-with-select">
							<el-button @click="onsearch" slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</el-col>
				</el-row>
				<el-table ref="multipleTable" :data="shoplist" tooltip-effect="dark" style="width: 100%"
					@selection-change="shopSelectionChange">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column prop="type" label="渠道" width="120">
						<template slot-scope="scope">{{ scope.row.type==1?'饿了么外卖':'饿了么零售' }}</template>
					</el-table-column>
					<el-table-column prop="shopId" label="ID" width="120">
					</el-table-column>
					<el-table-column prop="name" label="店铺名称" show-overflow-tooltip>
					</el-table-column>
				</el-table>
			</div>
			<el-row :gutter="24">
				<el-col :span="19" style="height: 50px;display: flex;align-items: center;">
					<div style="margin-left: 50px;">共{{selnum}}件商品</div>
				</el-col>
				<el-col :span="5" style="height: 50px;display: flex;align-items: center;">
					<div style="text-align: right;margin-top: 10px;">
						<el-button @click="quxiao" round>取消</el-button>
						<el-button @click="tijiao" type="primary" round>确认提交</el-button>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				keywords1: '',
				price: null,
				keywords: '',
				radio: '1',
				fangshi: '1',
				pageNo: 1,
				pageSize: 10,
				tableData: [],
				catelist: [{}],
				goodslist: [{}],
				categoryId: 0,
				input2: "",
				form: {},
				multipleSelection: [],
				step: 1,
				shoplist: [],
				tableData1: [],
				options: [{
					value: '选项1',
					label: '全部渠道'
				}, {
					value: '选项2',
					label: '饿了么外卖'
				}, {
					value: '选项3',
					label: '饿了么零售'
				}],
				value: '选项1',
				select: '',
				shop_mul_sel: [],
				selnum: 0,
				type:""
			}
		},
		created() {
			this.getcatelist();
			this.getgoodslist();
			console.log()
		},
		computed: {
			pagedTableData() {
				return this.tableData.filter(
					(v, i) =>
					i >= (this.pageNo - 1) * this.pageSize &&
					i <= this.pageNo * this.pageSize - 1
				);
			}
		},
		methods: {
			shopSelectionChange(val) {
				this.shop_mul_sel = val;
				this.selshopnum = this.shop_mul_sel.length;
				console.log("this.multipleSelection", this.shop_mul_sel)
			},
			onsearch() {
				this.type = 3;
				this.getshoplist();
			},
			getshoplist() {
				const formData1 = new FormData()
				formData1.append('keywords', this.keywords1)
				formData1.append('type', this.type)
				formData1.append('act', "list")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = 'http://www.vssite.com/api/getShop.aspx'
				axios.post(url, formData1).then(data => {
					this.type = "";
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.shoplist = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			quxiao() {
				//this.step = 1;
				this.$router.go(0)
			},
			tijiao() {
				if (this.shop_mul_sel.length <= 0) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '至少选择一个店铺'
					});
					return;
				}
				var multipleSelection = this.multipleSelection;
				var shop_mul_sel = this.shop_mul_sel;
				var arrids = [];
				var shopids = [];
				multipleSelection.forEach(function(item, index) {
					if (item.id > 0) {
						arrids.push({
							id: item.id
						});
					}
				})
				shop_mul_sel.forEach(function(item, index) {
					if (item.id > 0) {
						shopids.push({
							id: item.id
						});
					}
				})
				this.$confirm('确定执行改价操作嘛？, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.actgaijia(arrids, shopids);
					console.log("dd")
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
				console.log("ss");
			},
			
			actgaijia(goodsids, shopids) {
				const loading = this.$loading({
					lock: false,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const formData1 = new FormData()
				formData1.append('shopids', JSON.stringify(shopids))
				formData1.append('goodsids', JSON.stringify(goodsids))
				formData1.append('act', "gaijia")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = 'http://www.vssite.com/api/Goods.aspx'
				axios.post(url, formData1).then(data => {
					if (data.data.ret != 0) {
						console.log("sss")
					}
					setTimeout(function() {
						loading.close();
					}, 200)
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功'
					});
					this.drawer = false;
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
				console.log("ddd")
			},
			
			xiayibu() {
				var multipleSelection = this.multipleSelection;
				var num = 0;
				multipleSelection.forEach(function(item, index) {
					if (item.id > 0) {
						num = num + 1;
					}
				})
				if (num > 0) {
					if (this.price <= 0 || this.price == null) {
						this.$message({
							showClose: true,
							type: 'warning',
							message: '请填写改价幅度'
						});
						return;
					}
					this.getshoplist();
					this.step = 2;
					this.selnum = num;
					console.log("dd")
				} else {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '至少选择一个商品'
					});
					return;
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				console.log("multipleSelection", this.multipleSelection);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.pageNo = val;
			},
			actmenu(id) {
				this.categoryId = id;
				this.getgoodslist();
				console.log(id)
			},
			handleEdit(index, row) {
				console.log("sdss", row);
				this.goToAboutPage();
				// 编辑操作
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.pageNo = 1;
			},
			goToAboutPage() {
				this.$router.push({
					path: '/form/basicForm?param=ssasasa',
					query: {
						title: '你好'
					}
				});
			},
			getgoodslist() {
				const loading = this.$loading({
					lock: false,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const formData1 = new FormData()
				formData1.append('act', "list")
				formData1.append('categoryId', this.categoryId.toString())
				formData1.append('token', "1f9e6cb4759d7e93d38280388a5dd120")
				const url = 'http://www.vssite.com/api/Goods.aspx'
				axios.post(url, formData1).then(data => {
					setTimeout(function() {
						loading.close();
					}, 200)
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.tableData = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			getcatelist() {
				const formData1 = new FormData()
				formData1.append('act', "list")
				formData1.append('token', "1f9e6cb4759d7e93d38280388a5dd120")
				const url = 'http://www.vssite.com/api/addGoodsCategory.aspx'
				axios.post(url, formData1).then(data => {
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.catelist = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			}
		}
	}
</script>

<style>
	.el-main {
		position: relative;
	}

	.body {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		padding: 20px;
	}

	.first-tip {
		width: 50%;
		float: left;
		height: 39px;
		line-height: 39px;
	}

	.steps-tip {
		font-weight: 500;
		text-align: left;
		font-size: 16px;
		position: relative;
		padding-left: 30px;
		line-height: 22px;
		display: block;
		margin-bottom: 10px;
	}

	.steps-tip em {
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		background: #F5F5F5;
		text-align: center;
		line-height: 20px;
		font-size: 14px;
	}

	.first-tip em {
		top: 9px;
	}

	.steps-tip em {
		background-color: #2DAED6;
		color: #FFF;
		position: absolute;
		left: 0;
		top: 0;
	}


	.el-row {
		margin-bottom: 0px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
</style>
